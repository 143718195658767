<template>
  <div class="pa-10">
    <title-page icon="mdi-file-chart-outline">
      {{ $t('reports.reports') }}
    </title-page>


    <v-breadcrumbs
        :items="[{text: $t('reports.reports')}]"
        divider=">"
    ></v-breadcrumbs>

    <v-row>
      <v-col md="4" v-for="(report, i) in reports" :key="'report-' + i">
        <v-card :to="report.to">
          <v-card-title>
            {{ report.label }}
          </v-card-title>
          <v-card-text class="text-center">
            <v-icon size="80">
              {{ report.icon ? report.icon : 'mdi-poll' }}
            </v-icon>

          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  computed: {
    reports() {
      // {label: 'Clients', disabled: true},
      // {label: 'Bornes', disabled: true},
      // {label: 'Type de bornes', disabled: true},
      // {label: 'Cartes bancaires expirées', disabled: true},
      // {label: 'Transactions financières', disabled: true},

      let reports = []

      if (this.currentUser.permissions.includes('REPORT_USAGE')) {
        reports.push({
          label: this.$t('reports.usages.usages'),
          to: {name: 'reports.usages'},
          icon: 'mdi-file-chart-outline'
        })
      }

      if (this.currentUser.permissions.includes('REPORT_OFFLINE')) {
        reports.push({
          label: this.$t('reports.offline.offline'),
          to: {name: 'reports.offline'},
          icon: 'mdi-wifi-strength-off-outline'
        })
      }

      if (this.currentUser.isZE && this.currentUser.permissions.includes('OPERATOR_WRITE')) {
        reports.push({
          label: this.$t('reports.financial.dashboard'),
          to: {name: 'reports.financial'},
          icon: 'mdi-chart-pie'
        })
      }

      if (this.currentUser.isZE && this.currentUser.permissions.includes('OPERATOR_WRITE')) {
        reports.push({
          label: this.$t('recharge-service.recharge-service'),
          to: {name: 'reports.energy-provider'},
          icon: 'mdi-lightning-bolt'
        })

        reports.push({
          label: this.$tc('proforma-invoice.proforma-invoice', 2),
          to: {name: 'proforma-invoices'},
          icon: 'ico-invoice'
        })

        reports.push({
          label: this.$tc('invoice.invoice', 2),
          to: {name: 'invoices'},
          icon: 'ico-invoice'
        })

        reports.push({
          label: this.$tc('billing-request.billing-request', 2),
          to: {name: 'billing-requests'},
          icon: 'mdi-invoice-text-multiple-outline'
        })
      }


      return reports
    }
  }

}
</script>

<style lang="scss">

</style>
